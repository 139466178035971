const CryptoJS = require('crypto-js');
let host = `${window.location.origin}${process.env.VUE_APP_PUBLIC_PATH || '/'}`;
if(!host.endsWith("/")){
    host = `${host}/`;
}

let base_url = `${host}scheduling/`;
let vcip_url = `${host}api/`;
let report_url = `${host}api/`

let mis_endPoints = {
    create: "mis/create"
}
let typeOfEndPoints = [ 'users', 'customers' , 'backopadmins','calls' , 'scheduling', 'pincodes']
// all api endpoints related to scheduler server
let end_points = {
    logout: (uid) => `customers/logout/${uid}`,
    setFirstTimePass: (uid) => `customers/${uid}/setFirstTimePass`,
    agentLogin: "BackopAdmins/login",
    backopAdminVerifyJwt: "backopadmins/verify-autologin",
    setPass: (uid) => `backopAdmins/${uid}/setPass`,
    resetPass: (cid, bid) => `customers/${cid}/backopAdmins/${bid}/resetPass`,
    forgotAdminPass: "customers/forgotAdminPassword",
    forgotPass: "backopAdmins/forgotPassword",
    verifyAgentEmail: "backopAdmins/verifyAgentEmail",
    verifyEmailOTP: `BackopAdmins/verifyEmailOTP`,
    customerLogin: "customers/login",
    polling_api: requestId => `customers/getCall?requestId=${requestId}`,
    verify_call: `customers/verifyCall`,
    superAdminLogin: "Users/login",
    cancel_call: "calls/updateCall",
    face_match: "calls/faceMatchScore",
    initiate_direct_call: id => `calls/initiate/${id}`,
    update_direct_call_status: id => `calls/${id}/updateDirectStatus`,
    update_call_timestamp: id => `calls/${id}/callTimeStamps`,
    register_missed_call: id => `calls/${id}/registerMissedCall`,
    trigger_call_back: id => `calls/${id}/triggerCallBack`,
    custom_service_caller: (client, service) => `BackopAdmins/custom/${client}/${service}`,
    update_call_status: "calls/updateCallStatus",
    reschedule_call: "customers/updateCall",
    start_call: "customers/initiateCall",
    update_device_data: callId => `calls/${callId}/updateDeviceInfo`,
    update_admin_feedback: sessionId => `calls/${sessionId}/updateCallAdminFeedback`,
    vcip_call_count: userId => `BackopAdmins/${userId}/calls/count?where={"output":{"exists":true}, "output.createSessionResp.result.sessionId": {"exists" : true}}`,
    call_count: userId => `BackopAdmins/${userId}/calls/count?where={"confSession":{"exists":true}}`,
    agent_create_call: userId => `BackopAdmins/${userId}/calls`,
    get_res_manager: userId => `BackopAdmins/${userId}/getREOfManager`,
    get_res_manager_details: userId => `BackopAdmins/${userId}/getManagerDetails`,
    get_res_manager_count: userId => `BackopAdmins/${userId}/getCallCounts`,
    get_all_calls: userId => `BackopAdmins/${userId}/calls?filter={"where":{"confSession":{"exists":true}}}`,
    get_all_calls_paginated: (userId, limit, currentPage) => `BackopAdmins/${userId}/calls?filter={"where":{"confSession":{"exists":true}},"limit":${limit},"skip":${(currentPage - 1) * limit},"order":"id DESC"}`,
    // vcip_get_all_calls: (userId, limit, currentPage) => `BackopAdmins/${userId}/calls?filter={"where":{"output":{"exists":true},"output.createSessionResp.result.sessionId": {"exists" : true}},"limit":${limit},"skip":${(currentPage - 1) * limit},"order":"id DESC"}`,
    // vcip_get_all_calls: (userId) => `BackopAdmins/${userId}/getCalls`,
    vcip_get_categorized_calls: (userId) => `BackopAdmins/${userId}/getCategorizedCalls`,
    get_agent_data: userId => `BackopAdmins/${userId}`,
    get_customer_data: userId => `customers/${userId}`,
    update_customer_grants: userId => `customers/${userId}/updateCustomerGrants`,
    get_all_agents: customerId => `customers/${customerId}/backopAdmins`,
    get_all_admins: userId => `customers/${userId}/backopAdmins?filter={"where":{"role":"admin"}}`,
    create_agent: customerId => `customers/${customerId}/backopAdmins`,
    get_global_configs: customerId => `customers/${customerId}/globalConfigs`,
    create_customer: `customers/`,
    delete_persona_admin: (customerId, agentId) => `backopAdmins/${agentId}/deleteAgent`,
    get_all_customers: `customers/`,
    otp_verify_customer: customerId => `customers/${customerId}/verifyotp`,
    otp_resend_customer: customerId => `customers/${customerId}/resendotp`,
    otp_resend_backops: backopsId => `BackopAdmins/${backopsId}/resendotp`,
    otp_verify_backops: backopsId => `BackopAdmins/${backopsId}/verifyotp`,
    get_call_config: `Calls/getCallSession`,
    update_audit: callId => `Calls/${callId}/updateCallAudited`,
    delete_customer_agent: (customerId, agentId) => `customers/${customerId}/backopAdmins/${agentId}`,
    audited_backops_customer: (customerId, status) => `customers/${customerId}/getAuditedCalls/${status}`,
    audited_call_backops: (customerId, backopsId, status) => `customers/${customerId}/getAuditedBackopsCalls/${backopsId}/${status}`,
    maps_embed: (lat, lon, apiKey) => `https://www.google.com/maps/embed/v1/view?key=${apiKey}&center=${lat},${lon}&zoom=15`,
    upload_files: "customers/uploadfile",
    upload_files_v2: "BackopAdmins/upload",
    download_files: "customers/downloadfile",
    temp_auth: "customers/getTempToken",
    csvValidator: "customers/validate-dropdown-csv",
    dropDownFields: "customers/getDropDown/",
    getSkills: (customerId)=> `customers/${customerId}/getSkills`,
    getCall_SessionDetails: sessionId => `Calls/getSessionDetails/${sessionId}`,
    updateCallSessionData: sessionId => `Calls/updateSessionData/${sessionId}`,
    sessionFeedbackStatus: sessionId => `Calls/sessionFeedbackStatus/${sessionId}`,
    update_sub_admin_access: (subAdminID) => `BackopAdmins/updateSubAdminAccess/${subAdminID}`,
    self_update_flow_list: "BackopAdmins/selfUpdateFlowList",
    bulk_update_sub_admin_flow_list: "customers/bulkUpdateSubAdminAssignedFlows",
    re_activity: "calls/activityChecker",
    upload_pincode: "pincodes/uploadcsv",
    pincode_checker:"pincodes/blacklistedpincode",
    get_next_user: "BackopAdmins/getNextEndUser",
    toggle_status: "BackopAdmins/toggleStatus",
    dropped: id => `Calls/${id}/dropped`,
    isValidCallBackUrl: url => `customers/isValidCallbackUrl?url=${url}`,
    updateCallHistory: 'customers/update-call-history'
}
// all endpoints related to openvidu server
let vcip_end_points = {
    get_session_by_id: sessionId => `${vcip_url}sessions/all/${sessionId}`,
    get_session_token: `${vcip_url}sessions/token`,
    verify_session: `${vcip_url}sessions/verify`,
    end_session: `${vcip_url}sessions/end`,
    save_session: `${vcip_url}sessions/user`,
    send_chunks: `${vcip_url}sessions/chunks`,
    verify_token: `${vcip_url}sessions/verifyToken`,
    translate_instruction: `${vcip_url}sessions/translate`,
    send_cb: `${vcip_url}sessions/sendCb`,
    get_session_details: sessionId => `${vcip_url}sessions/all/${sessionId}`,
    start_recording: `${vcip_url}sessions/startrecording`,
    send_email: `${vcip_url}reschedule/sendlink`,
    location: `${vcip_url}sessions/location`,
    ipFinder: `${vcip_url}ip-finder`,
    feedback: `${vcip_url}sessions/feedback`,
    callback_url: `${vcip_url}reschedule/callback`,
    send_email_reschedule: `${vcip_url}reschedule/sendemail`,
    session_details: (sessionId) => `${vcip_url}sessions/details/${sessionId}`,
    device_compatibility_check: `${vcip_url}sessions/checkDeviceBlackList`,
    black_list_device: `${vcip_url}devices`,
    delete_black_list_device: (deviceId) => `${base_url}BackopAdmins/devices/${deviceId}`,
    download_image: `${base_url}Calls/downloadimage`,
    
    /**
     * @deprecated (for more details refer VKYC-6275)
     */
    get_call_status_by_sessionid : `${vcip_url}sessions/status`,

    /**
     * Fix (VKYC-6275)
     * Encrypting the following route, Axis Bank followed by Infosec team
     * raised the concern for this. 
     */
    get_call_status_by_sessionId_enc: `${vcip_url}sessions/enc/status`,
    
    deviceDetails:`${base_url}customers/getDeviceDetails`,
    forensics: `${base_url}customers/forensics`,
}

let chime_end_points = {
        initiate_meeting : sessionId => `${vcip_url}meetings/${sessionId}`
}

let sideBarItems = {
    "conference": [{
        "icon": "fas fa-plus",
        "text": "New Call",
        "path": "/dashboard/conference/new"
    },
    {
        "icon": "fas fa-history",
        "text": "Call History",
        "path": "/dashboard/conference/history"
    }, {
        "icon": "fas fa-video",
        "text": "Video Session",
        "path": "/dashboard/conference/initiator"
    }
    ],
    "superAdmin": [{
        "icon": "fas fa-user-plus",
        "text": "New Customer",
        "path": "/super/panel/new"
    }, {
        "icon": "fas fa-users-cog",
        "text": "All Customers",
        "path": "/super/panel/customer"
    },
    //  {
    //     "icon": "fas fa-mobile",
    //     "text": "Blacklist Devices",
    //     "path": "/super/panel/blackList"
    // }
],
    "teams": [
        {
            "icon": "fas fa-user-plus",
            "text": "My Team",
            "path": "/teams"
        }
    ],
    "dashboard": [{
        "icon": "fas fa-history",
        "text": "Call History",
        "path": "/dashboard/history"
    }, {
        "icon": "fas fa-video",
        "text": "Video Session",
        "path": "/dashboard/video"
    }],
    "settings": [{
        "icon": "fas fa-cogs",
        "text": "Call Configurations",
        "path": "/admin/control/settings/basic",
        "journeyType": 'VKYC',
        "showTooltip": true
    },
    {
        "icon": "fas fa-globe-americas",
        "text": "Advanced Settings",
        "path": "/admin/control/settings/advanced-settings",
        "journeyType": 'VKYC',
        "showTooltip": false
    },
    {
        "icon": "fas fa-info-circle",
        "text": "Instruction page",
        "path": "/admin/control/settings/instruction-page",
        "journeyType": 'VKYC',
        "showTooltip": false
    },
    {
        "icon": "fas fa-question-circle",
        "text": "Questionnaires",
        "path": "/admin/control/settings/instruction-set",
        "journeyType": 'VKYC',
        "showTooltip": false,
        "children": [
            {
                "icon": "fas fa-check-circle",
                "text": "Mandatory Pool",
                "path": "/admin/control/settings/instruction-set/mandatory"
            },
            {
                "icon": "fas fa-circle",
                "text": "Optional Pool",
                "path": "/admin/control/settings/instruction-set/optional"
            }
        ]
    },
    {
        "icon": "fas fa-users-cog",
        "text": "Admin Form",
        "path": "/admin/control/settings/admin-form",
        "journeyType": 'VKYC',
        "showTooltip": false
    }],
    "unassistedSettings": [
        {
            "icon": "fas fa-info-circle",
            "text": "UVKYC Call Configurations",
            "path": "/admin/control/settings/uvkyc-basic",
            "journeyType": 'UVKYC',
            "showTooltip": true
        },
        {
            "icon": "fas fa-question-circle",
            "text": "UVKYC Questionnaires",
            "path": "/admin/control/settings/uvkyc-instruction-set",
            "journeyType": 'UVKYC',
            "showTooltip": true
        }
    ],
    "controlPanel": [{
        "icon": "fas fa-user-alt",
        "text": "New Admin",
        "path": "/admin/control/createUser"
    },
    {
        "icon": "fas fa-user-tie",
        "text": "New Manager",
        "path": "/admin/control/createManager"
    }, {
        "icon": "fas fa-user-plus",
        "text": "New Agent",
        "path": "/admin/control/create"
    }, {
        "icon": "fas fa-user-friends",
        "text": "All Users",
        "path": "/admin/control/users"
    },
    {
        "icon": "fas fa-file-alt",
        "text": "MIS Reports",
        "path": "/admin/control/mis-reports"
    },
    {
        "icon": "fas fa-history",
        "text": "Audit History",
        "children": [{
            "icon": "fas fa-clipboard-list",
            "text": "Pending Audits",
            "path": "/admin/control/audit/pending",
        }, {
            "icon": "fas fa-check-double",
            "text": "Completed Audits",
            "path": "/admin/control/audit/completed",
        }]
    }
    ]
}

module.exports = {
    base_url: base_url,
    end_points: end_points,
    sideBar: sideBarItems,
    vcip_end_points: vcip_end_points,
    host: host,
    report_url: report_url,
    mis_endPoints: mis_endPoints,
    typeOfEndPoints: typeOfEndPoints,
    chime_end_points: chime_end_points,
}
